'use strict';

var formValidation = require('../components/formValidation');
var createErrorNotification = require('base/components/errorNotification');
const { isMobile } = require('../components/util');

function updateButtonAttributes() {
    if (isMobile()) {
        $('.btn-return-login-redirect').attr('tabindex', '-1').attr('aria-hidden', 'true');
    } else {
        $('.btn-return-login-redirect').attr('tabindex', '0').attr('aria-hidden', 'false');
    }
}

/**
 * function used to initiate an ajax call to submit login form.
 * Process the response accordingly.
 */
var submitLoginForm = function (form) {
    var url = form.attr('action');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            if (!data.success) {
                $.spinner().stop();
                formValidation(form, data);
                $('form.login').trigger('login:error', data);
            } else {
                $.spinner().stop();
                $('form.login').trigger('login:success', data);
                location.href = data.redirectUrl;
            }
        },
        error: function (data) {

            if (data.responseJSON.redirectUrl) {
                window.location.href = data.responseJSON.redirectUrl;
            } else {
                $('form.login').trigger('login:error', data);
                $.spinner().stop();
            }
        }
    });

    return false;
}

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            $.spinner().start();
            $('form.login').trigger('login:submit', e);
            submitLoginForm(form);
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $.spinner().start();

            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (!data.success) {
                        $('form.registration').trigger('login:register:error', data);
                        formValidation(form, data);
                    } else {
                        $('form.registration').trigger('login:register:success', data);

                        try {
                            dataLayer.push({
                                'account': {
                                    'channel': data.userRegistration.channel
                                },
                                'event': 'account_action'
                            });

                            if (data.triggerNewsLetterGtm !== null && data.triggerNewsLetterGtm.status) {
                                dataLayer.push({
                                    'newsletter_type': data.triggerNewsLetterGtm.type,
                                    'event': 'newsletter_subscription'
                                });
                            }

                        } catch (e) {
                            console.warn('Unable to trigger GTM after account creation');
                        }

                        location.href = data.redirectUrl;


                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $('body').on('submit', '.reset-password-form', function (e) {
            e.preventDefault();
            var form = $(this);
            var url = form.attr('action');
            $.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('#email-error-msg').html($('#reset-password-email').attr('data-missing-error'));
                    } else {
                        $('.request-password-title').addClass('title-check-email').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<span class="icon-E-MAIL">' + '</span>' +
                                '<p>' + data.receivedMsgBody + '</p>');
                        $('#requestPasswordResetModal .modal-content').prepend('<div class="reset-password-email"><p class="reset-email">' + data.email + '</p></div>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal').addClass('d-none');
                        } else {

                            $('.send-email-btn').empty()
                                .html('<a href="' +
                                    data.returnUrl +
                                    '" class="btn btn-primary btn-block d-none">' +
                                    data.buttonText + '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    }
};

$('#add-to-sms-list').on("change", function() {
    var $formPhone = $(".registration-phone");
    if ($(this).is(":checked")) {
        $formPhone.prop('required', true);
        $formPhone.parent().addClass('required');
    } else {
        $formPhone.prop('required', false);
        $formPhone.parent().removeClass('required');
    }
});

var $requiredFields = $('.registration input[required]');
var $submitButton = $('.registration .submit-registration-revamp');
function updateButtonStyle() {
    var allFilled = $requiredFields.toArray().every(function(field) {
        return $(field).val().length;
    });
    if (allFilled) {
        $submitButton.css('cursor', 'pointer');
    } else {
        $submitButton.css('cursor', 'default');
    }
}
$submitButton.on('mouseenter', updateButtonStyle);

$('.btn-account').on('click', function (e) {
    $('#login').addClass('d-none');
    $('#register').addClass('d-block');
    e.preventDefault();
});
$('.btn-return-login').on('click', function (e) {
    $('#login').removeClass('d-none');
    $('#register').removeClass('d-block');
    e.preventDefault();
});

setTimeout(function () {
    $('#popup-message').hide();
}, 5000);

updateButtonAttributes();

$(window).on('resize', function () {
    updateButtonAttributes();
});